import { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { CoreContext } from "../contexts";
import { uploadAdvertiserData } from "../requests";
import FileUpload from "./FileUpload";
import Header from "./Header";
import Loader from "./Loader";

// Define the per-item schema
const AdvertiserObject = z.object({
  domain: z.string(),
  type: z.enum([
    "enterprise",
    "premium",
    "pro",
    "cpc",
    "affiliate",
    "nonPartner",
  ]),
  isExcluded: z.boolean(),
  isPriority: z.boolean(),
  logo: z.string().url().or(z.literal("")),
  allowNonPartnerProducts: z.boolean(),
  pricerunnerMerchantName: z.string(),
});
// Define the array schema
const AdvertiserList = z.array(AdvertiserObject);

const Advertisers: FC = () => {
  const { state } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  return (
    <div className="padding max-width">
      <Header
        leftButton={() => (
          <Link to="/plugins" className="white white-btn small-padding">
            Back
          </Link>
        )}
        title="Advertisers Settings"
      />
      <div className="field" style={{ justifyContent: "center" }}>
        <input
          placeholder="Your token"
          type="text"
          onChange={(e) => setToken(e.target.value)}
          value={token}
          className="search"
        />
      </div>
      <p style={{ textAlign: "center" }}>
        <i>It can take up to 60 seconds to see changes on the platform</i>
      </p>
      <FileUpload
        onUpload={(file) => uploadAdvertiserData(state.apiKey, file, token)}
        parse={(d) => AdvertiserList.safeParse(d)}
        setLoading={setLoading}
      />
      {loading && <Loader center />}
    </div>
  );
};

export default Advertisers;
