import { ChangeEvent, Dispatch, SetStateAction, useState, useRef } from "react";
import { SafeParseReturnType } from "zod";

interface Props<T, U> {
  onUpload: (file: File) => Promise<boolean>;
  parse: (data: unknown) => SafeParseReturnType<T, U>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

function FileUpload<T, U>({ onUpload, parse, setLoading }: Props<T, U>) {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  // Use a ref to manually reset the file input field
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const file = e.target.files?.[0];
    if (!file) {
      setLoading(false);
      return;
    }

    try {
      // Read file as text
      const fileText = await file.text();
      // Parse JSON
      const parsed: unknown = JSON.parse(fileText);

      // Validate with Zod
      const result = parse(parsed);
      if (!result.success) {
        setError(
          "Invalid JSON structure: " + JSON.stringify(result.error.issues)
        );
        setLoading(false);
        return;
      }

      const isSuccess = await onUpload(file);

      if (!isSuccess) {
        setError("Upload failed: Server Error");
        setLoading(false);
        return;
      }

      setSuccess("File uploaded successfully!");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setError("Error reading or uploading file.");
    } finally {
      // Clear the file input after processing
      e.target.value = "";
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: 400,
        margin: "2rem auto",
        padding: "2rem",
        borderRadius: "0.5rem",
        boxShadow: "0 2px 6px rgba(0,0,0,0.15)",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: "1rem" }}>Upload Advertiser Data</h2>
      <p style={{ marginBottom: "1.5rem", textAlign: "center" }}>
        Please upload a valid <strong>.json</strong> file.
      </p>
      <label
        htmlFor="file-upload"
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          padding: "0.5rem 1rem",
          borderRadius: "0.25rem",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        Choose File
      </label>
      <input
        ref={fileInputRef}
        id="file-upload"
        type="file"
        accept=".json"
        onChange={(e) => void handleFileChange(e)}
        style={{ display: "none" }} // Hide the native file input
      />
      {error && (
        <p style={{ color: "red", marginTop: "1rem", textAlign: "center" }}>
          {error}
        </p>
      )}
      {success && (
        <p style={{ color: "green", marginTop: "1rem", textAlign: "center" }}>
          {success}
        </p>
      )}
    </div>
  );
}

export default FileUpload;
