export interface PluginField {
  id:
    | "domain"
    | "productUrl"
    | "proxy"
    | "imageProxy"
    | "page.userAgent"
    | "page.waitForSelector"
    | "page.waitForTimeout"
    | "page.waitUntil"
    | "allowJquery"
    | "enableGifs"
    | "sources.currency"
    | "sources.currencyCode"
    | "sources.image"
    | "sources.price"
    | "sources.title"
    | "domSelectors.id.dom"
    | "domSelectors.id.key"
    | "domSelectors.brand.dom"
    | "domSelectors.images.dom"
    | "domSelectors.images.heightParam.name"
    | "domSelectors.images.heightParam.value"
    | "domSelectors.images.widthParam.name"
    | "domSelectors.images.widthParam.value"
    | "domSelectors.price.dom"
    | "domSelectors.price.normalize"
    | "domSelectors.price.divisor"
    | "domSelectors.price.roundingMethod"
    | "domSelectors.title.dom"
    | "domSelectors.currency.dom"
    | "domSelectors.currencyCode.dom"
    | "domSelectors.shippingPrice.value"
    | "domSelectors.shippingPrice.currency"
    | "domSelectors.description.dom"
    | "domSelectors.gtin.dom"
    | "domSelectors.availability.inStock.dom"
    | "domSelectors.availability.inStock.value"
    | "domSelectors.availability.inStock.isAmount"
    | "domSelectors.availability.outOfStock.dom"
    | "domSelectors.availability.outOfStock.value"
    | "domSelectors.availability.outOfStock.isAmount";
  label: string;
  type: "text" | "select" | "switch";
  options?: string[];
  hint: string;
  hide?: boolean;
  dividerBefore?: boolean;
}

const pluginCoreFieldHints: Partial<Record<PluginField["id"], string>> = {
  productUrl:
    "Insert a valid Product URL which will be used to test the domain.",
  domain:
    "Insert a valid URL which will be parsed to the required format. Cannot start with www.",
  proxy:
    "Proxy is disabled by default. Use datacenter proxy if proxy is needed for a store. Use static for stores that whitelisted us and residential if it is a whitelisted residential by Brighdata. Use unlocker if none of the previous apply/work - but it will be a lot slower!",
  imageProxy:
    "Image Proxy is disabled by default. Use the same rules as for the proxy field",
  "page.userAgent": "Adds custom user-agent header to the HTTP request.",
  "page.waitForSelector":
    "Waits for a given selector to return an element (Optional, best use when domSelectors are not working with a valid selector)",
  "page.waitForTimeout":
    "Waits for given ms after the page load is started. (Optional, best use waitUntil)",
  "page.waitUntil":
    "Can take the following values: domcontentloaded (default, DOMContentLoaded event is fired), networkidle0 (consider navigation to be finished when there are no more than 0 network connections for at least 500 ms), networkidle2 (consider navigation to be finished when there are no more than 2 network connections for at least 500 ms)",
};

const pluginDomSelectorFields: PluginField[] = [
  {
    id: "domSelectors.id.dom",
    label: "id:dom",
    type: "text",
    hint: "???",
    hide: true,
  },
  {
    id: "domSelectors.id.key",
    label: "id:key",
    type: "text",
    hint: "???",
    hide: true,
  },
  {
    id: "domSelectors.brand.dom",
    label: "brand:dom",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.images.dom",
    label: "images:dom",
    type: "text",
    hint: "???",
    dividerBefore: true,
  },
  {
    id: "domSelectors.images.heightParam.name",
    label: "images.heightParam.name",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.images.heightParam.value",
    label: "images:heightParam.value",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.images.widthParam.name",
    label: "images:widthParam.name",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.images.widthParam.value",
    label: "images:widthParam.value",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.price.dom",
    label: "price:dom",
    type: "text",
    hint: "???",
    dividerBefore: true,
  },
  {
    id: "domSelectors.price.normalize",
    label: "price:normalize",
    type: "switch",
    hint: "???",
  },
  {
    id: "domSelectors.price.divisor",
    label: "price:divisor",
    type: "text",
    hint: "Specifies the divisor for price conversion. Use 1 if the price is in dollars, or 100 if the price is in cents.",
  },
  {
    id: "domSelectors.price.roundingMethod",
    label: "price:roundingMethod",
    type: "select",
    options: ["floor", "ceil", "round"],
    hint: "Choose how the price should be rounded. Options: floor (rounds down), ceil (rounds up), round (standard rounding), or none (no rounding applied).",
  },
  {
    id: "domSelectors.currency.dom",
    label: "currency:dom",
    type: "text",
    hint: "???",
    dividerBefore: true,
  },
  {
    id: "domSelectors.currencyCode.dom",
    label: "currencyCode:dom",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.shippingPrice.value",
    label: "shippingPrice:value",
    type: "text",
    hint: "Select the shipping price",
    dividerBefore: true,
  },
  {
    id: "domSelectors.shippingPrice.currency",
    label: "shippingPrice:currency",
    type: "text",
    hint: "Select the currency for shipping price",
  },
  {
    id: "domSelectors.title.dom",
    label: "title:dom",
    type: "text",
    hint: "???",
    dividerBefore: true,
  },
  {
    id: "domSelectors.description.dom",
    label: "description:dom",
    type: "text",
    hint: "???",
  },
  {
    id: "domSelectors.gtin.dom",
    label: "gtin:dom",
    type: "text",
    hint: "Select the GTIN of the product (supports EAN and UPC, too)",
  },
  {
    id: "domSelectors.availability.inStock.dom",
    label: "availability:inStock:dom",
    type: "text",
    hint: "Selector to get the text indicating the product is in stock",
    dividerBefore: true,
  },
  {
    id: "domSelectors.availability.inStock.value",
    label: "availability:inStock:value",
    type: "text",
    hint: "Value that must exact match the selected in stock text",
  },
  {
    id: "domSelectors.availability.inStock.isAmount",
    label: "availability:inStock:isAmount",
    type: "switch",
    hint: "If true, only numbers are kept from the selected in stock text and stock is true if number is greater than 0",
  },
  {
    id: "domSelectors.availability.outOfStock.dom",
    label: "availability:outOfStock:dom",
    type: "text",
    hint: "Selector to get the text indicating the product is out of stock",
  },
  {
    id: "domSelectors.availability.outOfStock.value",
    label: "availability:outOfStock:value",
    type: "text",
    hint: "Value that must exact match the selected out of stock text",
  },
  {
    id: "domSelectors.availability.outOfStock.isAmount",
    label: "availability:outOfStock:isAmount",
    type: "switch",
    hint: "If true, only numbers are kept from the selected out of stock text and stock is true if number is less than 1",
  },
];

export { pluginCoreFieldHints, pluginDomSelectorFields };
