import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Addons,
  Login,
  PrivateRoute,
  AmazonScraperSettings,
  Blocked,
  Libraries,
  Advertisers,
} from "./components";
import { Plugin, Plugins } from "./components/plugins";
import { CoreContextProvider, PluginsContextProvider } from "./contexts";

const App: React.FC = () => (
  <CoreContextProvider>
    <PluginsContextProvider>
      <Addons />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/plugins" component={Plugins} />
          <PrivateRoute
            exact
            path="/plugins/amazon-scraper-settings"
            component={AmazonScraperSettings}
          />
          <PrivateRoute path="/plugins/:domain" component={Plugin} />
          <PrivateRoute path="/blocked" component={Blocked} />
          <PrivateRoute path="/libraries" component={Libraries} />
          <PrivateRoute path="/advertisers" component={Advertisers} />
        </Switch>
      </Router>
    </PluginsContextProvider>
  </CoreContextProvider>
);

export default App;
