import axios, { isAxiosError } from "axios";
import { API_HOST } from "../constants";

interface BackendError {
  message?: string;
  code?: number;
}

const uploadAdvertiserData = async function (
  apiKey: string,
  file: File,
  token: string
) {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post<never, { data: { success: boolean } }>(
      `${API_HOST}/backend/v1/advertisers`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "multipart/form-data",
          "Advertisers-Token": token,
        },
      }
    );
    return response?.data?.success || false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return false;
  }
};

export default uploadAdvertiserData;
